
import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '@/lib/util'
import AbpBase from '@/lib/abpbase'
import PageRequest from '@/store/entities/page-request'
import SortingModel from "@/store/entities/sortingmodel";
import notification from "@/store/modules/notification";
import Entity from "@/store/entities/entity";

@Component
export default class NotificationList extends AbpBase {

  pagerequest: PageRequest = new PageRequest();

  async changeSort(data) {
    if (this.pagerequest.sorting == null) {
      this.pagerequest.sorting = [];
    }
    let item = this.pagerequest.sorting.find(x => x.fieldName === data.key)
    if (item == null) {
      let sortingModel = new SortingModel();
      sortingModel.fieldName = data.key;
      sortingModel.isDescendingDirection = data.order === "desc";
      this.pagerequest.sorting.push(sortingModel)
    } else {
      item.isDescendingDirection = data.order === "desc";
    }
    await this.getpage()
  }


  get list() {
    return this.$store.state.notification.list;
  };

  get loading() {
    return this.$store.state.notification.loading;
  }

  async pageChange(page: number) {
    this.$store.commit('notification/setCurrentPage', page);
    await this.getpage();
  }

  async search() {
    await this.pageChange(1);
  }

  async pagesizeChange(pagesize: number) {
    this.$store.commit('notification/setPageSize', pagesize);
    await this.getpage();
  }

  async getpage() {

    this.pagerequest.maxResultCount = this.pageSize;
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

    await this.$store.dispatch({
      type: 'notification/getAll',
      data: this.pagerequest
    })
  }


  get pageSize() {
    return this.$store.state.notification.pageSize;
  }

  get totalCount() {
    return this.$store.state.notification.totalCount;
  }

  get currentPage() {
    return this.$store.state.notification.currentPage;
  }

  columns = [{
    title: this.L('Message'),
    key: 'message'
  }, {
    title: this.L('Date'),
    key: 'date',
    width: '100px',
    render: (h: any, params: any) => {
      return h('span', new Date(params.row.date).toLocaleDateString())
    }
  }, {
    title: this.L('State'),
    key: 'state',
    width: '100px',
    render: (h: any, params: any) => {
      return h('span', params.row.state === 0 ? 'Unread' : 'Read')
    }
  }, {
    title: this.L('Actions'),
    key: 'Actions',
    width: 200,
    render: (h: any, params: any) => {
      return h('div', [
        h('Button', {
          props: {
            type: 'primary',
            size: 'small',
            disabled: params.row.state !== 0
          },
          style: {
            marginRight: '5px'
          },
          on: {
            click: async () => {
              let input = new Entity<string>();
              input.id = params.row.id;
              await this.$store.commit('app/readNotification', {
                id: input,
              });
              await this.$store.commit('app/getUserNotifications');
              let that = this;
              setTimeout(
                  function () {
                    that.getpage();
                  }, 500);

            }
          }
        }, this.L('Read')),
        h('Button', {
          props: {
            type: 'primary',
            size: 'small',
            disabled: params.row.state !== 1
          },
          style: {
            marginRight: '5px'
          },
          on: {
            click: async () => {
              let input = new Entity<string>();
              input.id = params.row.id;
              await this.$store.commit('app/unreadNotification', {
                id: input,
              });
              await this.$store.commit('app/getUserNotifications');
              let that = this;
              setTimeout(
                  function () {
                    that.getpage();
                  }, 500);
            }
          }
        }, this.L('Unread'))
      ])
    }
  }]

  async created() {
    await this.getpage();
  }

}
